
import {defineComponent, ref, onMounted} from 'vue';
import axios from 'axios';
import TheHeader from '@/components/TheHeader.vue';
import OnError from '@/components/OnError.vue';
import ServersTable from '@/components/ServersTable.vue';
import UpdateTime from '@/components/UpdateTime.vue';
import HealthCheckTable from '@/components/HealthCheckTable.vue';
import TheFooter from '@/components/TheFooter.vue';


export default defineComponent({
  name: 'App',
  components: {
    TheHeader,
    OnError,
    ServersTable,
    HealthCheckTable,
    TheFooter,
    UpdateTime
  },
  setup() {
    const servers = ref<Array<StatusItem | BoxItem>>();
    const checks = ref<Array<ChecksStatusItem | ChecksBoxItem>>();
    const updated = ref<number>();
    onMounted(() => setInterval(() => {
      axios
          .get('http://127.0.0.1:20000/get')
          .then(
              res => {
                servers.value = res.data.servers;
                checks.value = res.data.healthchecks;
                updated.value = Number(res.data.updated);
              }
          )
          .catch(err => console.log(err));
    }, 2000));
    return {
      servers,
      checks,
      updated
    };
  }
});
