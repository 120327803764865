
import {defineComponent, PropType} from 'vue';
import TableItem from '@/components/HealthCheckTableItem.vue';

export default defineComponent({
  name: 'HealthCheckTable',
  props: {
    checks: {
      type: Array as PropType<Array<ChecksStatusItem | ChecksBoxItem>>,
      default: []
    }
  },
  components: {
    TableItem
  }
});
