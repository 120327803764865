
import {defineComponent, ref, PropType} from 'vue';
import useCheck from './useCheck';

export default defineComponent({
  name: 'HealthCheckTableItem',
  props: {
    check: {
      type: Object as PropType<ChecksStatusItem | ChecksBoxItem>,
      default: {}
    }
  },
  setup(props) {
    const collapsed = ref(true);
    const utils = useCheck(props);
    return {
      collapsed,
      ...utils
    };
  }
});
