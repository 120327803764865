import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_header = _resolveComponent("the-header")
  const _component_on_error = _resolveComponent("on-error")
  const _component_servers_table = _resolveComponent("servers-table")
  const _component_health_check_table = _resolveComponent("health-check-table")
  const _component_update_time = _resolveComponent("update-time")
  const _component_the_footer = _resolveComponent("the-footer")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_the_header),
    _withDirectives(_createVNode(_component_on_error, null, null, 512), [
      [_vShow, !_ctx.servers]
    ]),
    _createVNode(_component_servers_table, { servers: _ctx.servers }, null, 8, ["servers"]),
    _createVNode(_component_health_check_table, { checks: _ctx.checks }, null, 8, ["checks"]),
    _createVNode(_component_update_time, { updated: _ctx.updated }, null, 8, ["updated"]),
    _createVNode(_component_the_footer)
  ], 64))
}